<template>
  <div>
    <v-data-table :headers="creditHeader" :items="creditDetails">
      <template v-slot:[`item.indexNo`]="{ item }">
        {{ creditDetails.indexOf(item) + 1 }}
      </template>
      <template v-slot:[`item.effectiveDate`]="{ item }">
        {{ formattedTime(item.effectiveDate) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon @click="getPdfData(item)" color="info">picture_as_pdf</v-icon>
      </template>
      <template v-slot:[`item.referenceId`]="{ item }">
        {{ getReferenceId(item) }}
      </template>
    </v-data-table>
    <loading :active="isLoading" :loader="loader" />
  </div>
</template>

<script>
import moment from "moment";
import billingAccountAPI from "@/services/billingAccountAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import 'moment-timezone';


export default {
  name: "creditDetailsTable",
  props: ["creditDetails"],
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      loader: "bars",
      pdfData: "",
      creditHeader: [
        {
          text: "#",
          value: "indexNo",
          sortable: false,
        },

        {
          text: "Reference Id",
          value: "referenceId",
          align: "center",
          sortable: true,
        },
        {
          text: "Amount",
          value: "amount",
          align: "center",
          sortable: true,
        },
        {
          text: "Payment Method",
          value: "description",
          align: "center",
          sortable: true,
        },
        {
          text: "Transaction Date",
          value: "effectiveDate",
          align: "center",
          sortable: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: true,
        },
      ],
      newYorkTime: moment,
    };
  },
  mounted() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
  },
  methods: {
    getReferenceId(item) {
      // Return payPalPaymentId if present, otherwise return paymentId
      return item.payPalPaymentId ? item.payPalPaymentId : item.paymentId;
    },
    formattedTime(timestamp) {
      let newYorkTime = this.newYorkTime(timestamp).tz('America/New_York');
      return newYorkTime.format("MMMM Do YYYY, h:mm:ss a");
    },
    async getPdfData(details) {
      this.isLoading = true;
      let payload = {
        orderPaymentPreferenceId: details.paymentId,
        transactionType: "CREDIT",
        partyId: this.userDetail.partyId,
      };
      try {
        let response = await billingAccountAPI.transactionTypeBase64(payload);
        this.pdfData = response.base64;
        this.$emit(
          "get-credit-pdf-data",
          `data:application/pdf;base64,${this.pdfData}`
        );
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
  },
};
</script>