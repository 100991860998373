<template>
  <div>
    <v-data-table :headers="debitHeader" :items="debitDetails">
      <template v-slot:[`item.indexNo`]="{ item }">
        {{ debitDetails.indexOf(item) + 1 }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formattedTime(item.date) }}
      </template>
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-icon @click="getPdfData(item)" color="info">picture_as_pdf</v-icon>
      </template> -->
    </v-data-table>

    <loading :active="isLoading" :loader="loader" />
  </div>
</template>

<script>
import moment from "moment";
// import billingAccountAPI from "@/services/billingAccountAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import 'moment-timezone';

export default {
  name: "debitDetailsTable",
  components: {
    Loading,
  },
  props: ["debitDetails"],
  data() {
    return {
      newYorkTime: moment,
      isLoading: false,
      loader: "bars",
      pdfData: "",
      debitHeader: [
        {
          text: "#",
          value: "indexNo",
          sortable: false,
        },
        {
          text: "Reference Id	",
          value: "referenceId",
          align: "center",
          sortable: true,
        },
        // {
        //   text: "Order Payment Preference Id",
        //   value: "orderPaymentPreferenceId",
        //   align: "center",
        //   sortable: true,
        // },
        {
          text: "Amount",
          value: "maxAmount",
          align: "center",
          sortable: true,
        },
        {
          text: "Transaction Date",
          value: "date",
          align: "center",
          sortable: true,
        },
        // {
        //   text: "Action",
        //   value: "action",
        //   sortable: true,
        // },
      ],
    };
  },
  methods: {
    formattedTime(timestamp) {
      let newYorkTime = this.newYorkTime(timestamp).tz('America/New_York');
      return newYorkTime.format("MMMM Do YYYY, h:mm:ss a");
    },
    // async getPdfData(details) {
    //   this.isLoading = true;
    //   let payload = {
    //     orderPaymentPreferenceId: details.orderPaymentPreferenceId,
    //     transactionType: "DEBIT",
    //   };
    //   try {
    //     let response = await billingAccountAPI.transactionTypeBase64(payload);
    //     this.pdfData = response.base64;
    //     this.$emit("get-debit-pdf-data", `data:application/pdf;base64,${this.pdfData}`);
    //     this.isLoading = false;
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },
  },
};
</script>