var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.creditHeader,"items":_vm.creditDetails},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.creditDetails.indexOf(item) + 1)+" ")]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedTime(item.effectiveDate))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"info"},on:{"click":function($event){return _vm.getPdfData(item)}}},[_vm._v("picture_as_pdf")])]}},{key:"item.referenceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReferenceId(item))+" ")]}}],null,true)}),_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }